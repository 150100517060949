import Style from "./ModalImageCarousel.module.css";
import { useState } from "react";
import Slider from "react-slick";
import { useMediaQuery } from 'react-responsive'

const ModalImageCarousel = ({ images }) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(max-width: 1800px)'
  })

  console.log("--images--", images);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <Slider {...settings}>
        {images.map((image) => {
          return (
            <div>
              <img
                style={{ height: isDesktopOrLaptop ? "620px" : "760px", width: "100%" }}
                src={image}
                alt="listing"
              />
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export { ModalImageCarousel };
