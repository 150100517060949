import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useCreateShortLink } from "../../../core/api/share.api";
import { formatNumber, stringShorter } from "../../../core/utils/helpers.util";
import { InfoTitle } from "../../common/InfoTitle/InfoTitle";
import Style from "./ShareContainer.module.css";

const ShareContainer = ({AgentKey, ListingKey, UnparsedAddress, first_name , last_name, ListPrice, PublicRemarks, Media}) => {
  const [shareLink , setShareLink] = useState("")

  const createLink = useCreateShortLink();

  const generateQrCode = async ({AgentKey, ListingKey, UnparsedAddress, first_name , last_name, ListPrice, PublicRemarks, Media} ) => {

    const obj = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://rfreso.page.link",
        link: `https://app.realtyfeed.com/agent?AgentKey=${AgentKey}&ListingKey=${ListingKey}`,
        androidInfo: {
          androidPackageName: "com.realtyfeed",
        },
        iosInfo: {
          iosBundleId: "org.reactjs.native.example.RealtyFeed",
          iosAppStoreId: "1624878593",
        },
        socialMetaTagInfo: {
          socialTitle: UnparsedAddress,
          socialDescription: `${UnparsedAddress ? UnparsedAddress : ""} 
${first_name} ${last_name}
${formatNumber(ListPrice ? ListPrice.toString() : "")}$
${stringShorter(PublicRemarks, 200)}`,
          socialImageLink: Media ? Media : null,
        },
      },
      suffix: {
        option: "SHORT",
      },
    };

    createLink.mutate(obj, {
      onSuccess: async (val) => {
        if (val && val.data && val.data.shortLink) {
          setShareLink(val.data.shortLink);
        }
      },
    });

  }

  useEffect(() => {
    generateQrCode({AgentKey, ListingKey, UnparsedAddress, first_name , last_name, ListPrice, PublicRemarks, Media});
  }, []);

  return (
    <>
      <div className={Style.container}>
        <div id="printablediv">
          {/* <p
            style={{
              color: "#323233",
              fontFamily: "montserratBold",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {" "}
            Better Connected®
          </p> */}
          <p
            style={{
              width: "80%",
              margin: "0 auto",
              color: "#323233",
              fontFamily: "montserratBold",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            Scan the QR code to share listing details
          </p>
          <div style={{ width: "100px", height: "100px", margin: "20px auto" }}>
            {shareLink && (
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100px", width: "100px" }}
                value={shareLink}
                viewBox={`0 0 256 256`}
              />
            )}
          </div>
        </div>
        <p
          onClick={() => {
            let printContents =
              document.getElementById("printablediv").innerHTML;
            var a = window.open("", "", "height=600, width=600");
            a.document.write("<html>");
            a.document.write("<body >");
            a.document.write(printContents);
            a.document.write("</body></html>");
            a.document.close();
            a.print();
          }}
          className={Style.print}
        >
          {" "}
          Print{" "}
        </p>
      </div>
    </>
  );
}



export { ShareContainer }