import Style from "./ImagesContainer.module.css";
import Modal from 'react-modal';
import { useState } from "react";
import { ModalImageCarousel } from "./ModalImageCarousel/ModalImageCarousel";
import { useMediaQuery } from 'react-responsive'

Modal.defaultStyles.overlay.backgroundColor = "#00000099"
Modal.defaultStyles.overlay.zIndex = 9999999
Modal.defaultStyles.content.overflow = "hidden"
const ImagesContainer = ({ images }) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(max-width: 1800px)'
  })

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isDesktopOrLaptop ? "900px" : "1066px",
      height: isDesktopOrLaptop ? "650px" : "800px",
      background: "#fff",
      boxShadow: "0px 0px 0.5px 0.5px #ccc",
      zIndex: 99999999,
    },
  };
  
  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#root');

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      {modalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Listing Images"
        >
          <ModalImageCarousel images={images} />
        </Modal>
      )}
      <div className={Style.container}>
        <div style={{ flex: 1 }}>
          <img className={Style.mainImage} src={images[0]} alt="listing main" />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: "28px",
            // border: "1px solid black",
            height: "540px",
            // overflow: "hidden",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            flexWrap: "wrap",
          }}
        >
          {images && images.length > 1 && (
            <img className={Style.smallImage} src={images[1]} alt="listing" />
          )}
          {images && images.length > 2 && (
            <img
              style={{ marginLeft: "26px" }}
              className={Style.smallImage}
              src={images[2]}
              alt="listing"
            />
          )}
          {images && images.length > 3 && (
            <img
              style={{ marginTop: "17px" }}
              className={Style.smallImage}
              src={images[3]}
              alt="listing"
            />
          )}
          {images && images.length > 4 && (
            <div
              className={Style.smallImage}
              style={{
                position: "relative",
                // display: "inline-block",
                marginLeft: "26px",
                marginTop: "17px",
                // overflow: "hidden",
              }}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={images[4]}
                alt="listing"
              />

              {images.length > 5 && (
                <div
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "0",
                    width: "100%",
                    height: "100%",
                    borderRadius: "7px",
                    backgroundColor: "#00000097",
                    zIndex: "10",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "126px",
                      height: "48px",
                      color: "#151aae",
                      backgroundColor: "#ffff",
                      borderRadius: "7px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "12px",
                        fontFamily: "montserratMedium",
                        textAlign: "center",
                        marginRight: "7px",
                      }}
                    >
                      View more
                    </span>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src={
                        require("../../../assets/svg/Arrow_Down_Circle.svg")
                          .default
                      }
                      alt="arrow"
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { ImagesContainer };
