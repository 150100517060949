import Style from "./AgentInfo.module.css";
import { AgentView } from "./AgentView/AgentView";
import AgentImage from "../../../assets/images/img3x.png";
import { CardDetail } from "../../common/CardDetail/CardDetail";

const AgentInfo = ({
  agentName,
  agentDescription,
  agentPhone,
  agentTelephone,
  agentEmail,
  profileImage,
}) => {
  return (
    <>
      <div className={Style.container}>
        <AgentView
          agentImage={profileImage}
          agentName={agentName}
          agentDescription={agentDescription}
        />
        <p className={Style.activeListing}>
          <span style={{ fontFamily: "montserratBold" }}>6</span> total active
          listings
        </p>
        {agentPhone ? (
          <CardDetail
            text={agentPhone}
            iconHeight={16.7}
            iconWidth={16.7}
            containerStyle={{ marginTop: "30px" }}
            icon={
              <img
                src={require("../../../assets/svg/Calling.svg").default}
                alt="phone"
              />
            }
          />
        ) : null}
        {agentTelephone ? (
          <CardDetail
            text={agentTelephone}
            iconHeight={16.7}
            iconWidth={16.7}
            containerStyle={{ marginTop: "20px" }}
            icon={
              <img
                src={require("../../../assets/svg/mobile.svg").default}
                alt="phone"
              />
            }
          />
        ) : null}
        {agentEmail ? (
          <CardDetail
            text={agentEmail}
            iconHeight={16.7}
            iconWidth={16.7}
            containerStyle={{ marginTop: "20px" }}
            icon={
              <img
                src={require("../../../assets/svg/Message.svg").default}
                alt="phone"
              />
            }
          />
        ) : null}
      </div>
    </>
  );
};

export { AgentInfo };
