import { useMutation } from "@tanstack/react-query";
import { Http } from "./interceptors/http.interceptor";

export const PropertyListView = ({ query, key }) =>
  Http.get(`Property${query.length > 0 ? query : "?"}`, {
    headers: {
      "x-api-key": key,
    },
  });

export const PropertyListViewLoadMore = ({ query, key }) =>
  Http.get(query, {
    headers: {
      "x-api-key": key,
    },
  });

export const PropertyDetails = ({ ListingKey, key }) =>
  Http.get(`Property('${ListingKey}')`, {
    headers: {
      "x-api-key": key,
    },
  });

export const AgentProfileLoader = (key) =>
  Http.get(
    `https://api.realtyfeed.com/reso/agent-profile`,
    {
      headers: {
        "x-api-key": key,
      },
    }
  );

export const useProfileLoader = () => useMutation(AgentProfileLoader);
export const usePropertyDetails = () => useMutation(PropertyDetails);
export const usePropertyListView = () => useMutation(PropertyListView);
export const usePropertyListViewLoadMore = () => useMutation(PropertyListViewLoadMore);
