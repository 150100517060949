import { useState, useCallback } from 'react'
import Style from './ListingLocation.module.css';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { SectionTitle } from '../../common/SectionTitle/SectionTitle';

import { usePropertyListView } from '../../../core/api/property.api';

const ListingLocation = ({ Latitude, Longitude, listings, agentKey }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA4913w6cnR0Ilu6TaxVrDMn5WI2RNvHAw",
  });

  const [nearbyListings, setNearbyListings] = useState([]);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState(
    +Latitude && +Longitude
      ? {
          lat: +Latitude,
          lng: +Longitude,
        }
      : {
          lat: 37.75689441120425,
          lng: -122.4323088914144,
        }
  );

  const containerStyle = {
    width: "100%",
    height: "540px",
  };

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const propertyMutation = usePropertyListView();

  const onBoundsChanged = () => {
    if(map) {
      let bounds = map.getBounds();
      let ne = bounds.getNorthEast();
      let sw = bounds.getSouthWest();
      let nw = { lat: ne.lat(), lng: sw.lng() };
      let se = { lat: sw.lat(), lng: ne.lng() };
  
      let boundsCoordinates = {
        ne: { lat: ne.lat(), lng: ne.lng() },
        sw: { lat: sw.lat(), lng: sw.lng() },
        nw: nw,
        se: se,
      };
  
      let paths = [
        { lat: boundsCoordinates.ne.lat, lng: boundsCoordinates.ne.lng },
        { lat: boundsCoordinates.nw.lat, lng: boundsCoordinates.nw.lng },
        { lat: boundsCoordinates.sw.lat, lng: boundsCoordinates.sw.lng },
        { lat: boundsCoordinates.se.lat, lng: boundsCoordinates.se.lng },
        { lat: boundsCoordinates.ne.lat, lng: boundsCoordinates.ne.lng },
      ];
  
      const query = `?$select=ListingKey,Latitude,Longitude,ListPrice&$filter=geo.intersects(Coordinates, POLYGON((${paths.map(
        (row, i) => row.lng + " " + row.lat
      )},${paths[0].lng} ${paths[0].lat})))`;
  
      propertyMutation.mutate(
        { query: query, key: agentKey },
        {
          onSuccess: (val) => {
            const temp = val.data.value;
            setNearbyListings(temp);
          },
          onError: (er) => {
            console.log("error =>>>>", er);
          },
        }
      );
    }
  };
  return (
    <>
      <div className={Style.container}>
        <SectionTitle
          containerStyle={{ marginBottom: "26px" }}
          text={"Listing Location"}
        />
        <div style={{ marginTop: "26px" }}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onBoundsChanged={onBoundsChanged}
              options={{
                disableDefaultUI: true,
                fullscreenControl: true,
              }}
            >
              <Marker position={{ lat: +Latitude, lng: +Longitude }} />

              {listings &&
                listings.map((property, index) => (
                  <Marker
                    key={index}
                    position={{
                      lat: +property.Latitude,
                      lng: +property.Longitude,
                    }}
                  ></Marker>
                ))}
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};



export { ListingLocation }