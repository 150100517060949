import Style from './CustomButton.module.css';

const CustomButton = ({onClick, text}) => {
    
    return (
      <div onClick={onClick} className={Style.container}>
        <p>{text}</p>
      </div>
    );
}



export { CustomButton }