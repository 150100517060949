import { SectionTitle } from '../../common/SectionTitle/SectionTitle';
import Style from './AboutThisHome.module.css';

const AboutThisHome = ({text}) => {
  return (
    <>
      <div className={Style.container}>
        <SectionTitle text="About this home" />
        <p className={Style.description}>
         {text}
        </p>
      </div>
    </>
  );
};



export { AboutThisHome }