import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { CarouselItem } from "../CarouselItem/CarouselItem";
import { useCallback, useState } from "react";

const ListingCarousel = ({data,agentKey}) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };

  const [dragging, setDragging] = useState(false)

  const handleBeforeChange = useCallback(() => {
    console.log('handleBeforeChange')
    setDragging(true)
}, [setDragging])

const handleAfterChange = useCallback(() => {
    console.log('handleAfterChange')
    setDragging(false)
}, [setDragging])



  return (
    <div>
      <Slider
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        {...settings}
      >
        {data.map((row) => {
          return (
            <CarouselItem
              key={row.ListingKey}
              dragging={dragging}
              listing={row}
              agentKey={agentKey}
            />
          );
        })}
      </Slider>
    </div>
  );
};



export { ListingCarousel }