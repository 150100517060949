import Style from "./KeyValue.module.css";

const KeyValue = ({ containerStyle, keyTitle, value }) => {
  return (
    <div className={Style.row}>
      <div className={Style.key}>{keyTitle}</div>
      <div className={Style.value}>{value}</div>
    </div>
  );
};

export { KeyValue };
