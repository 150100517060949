import Style from './CardDetail.module.css';

const CardDetail = ({ text, icon, containerStyle, iconWidth, iconHeight, color }) => {
  return (
    <div className={Style.container} style={{ ...containerStyle }}>
      <div
        style={{
          width: `${iconWidth}px`,
          height: `${iconHeight}px`,
          // border: "1px solid black",
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {icon}
      </div>
      <p style={color ? { color: color } : {}} className={Style.text}>
        {text}
      </p>
    </div>
  );
};



export { CardDetail }