import { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { usePropertyListView, usePropertyListViewLoadMore } from "../../../core/api/property.api";
import { Listing } from "../Listing/Listing";
import Style from "./LatestListings.module.css";
const queryString = require("query-string");

const LatestListings = ({AgentKey, AgentInfo}) => {
  const [listings, setListings] = useState([]);
  const [nextLink, setNextLink] = useState("");
  
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const propertyMutation = usePropertyListView();
  const propertyGetNext = usePropertyListViewLoadMore()

  useEffect(() => {
    if (parsed && parsed.AgentKey) {
      const query = `?$select=Media,ListingKey,UnparsedAddress,ListPrice,PropertyType,BedroomsTotal,BathroomsTotalInteger,BuildingAreaTotal,PublicRemarks,InternetAddressDisplayYN,ModificationTimestamp&$orderby=ModificationTimestamp desc&$top=5`;

      propertyMutation.mutate(
        { query: query, key: parsed.AgentKey },
        {
          onSuccess: (val) => {
            const temp = val.data.value;
            setListings(temp);
            setNextLink(val.data['@odata.nextLink'])
          },
          onError: (er) => {
            console.log("error =>>>>", er);
          },
        }
      );
    }
  }, []);

  const fetchData = () => {
    if (parsed && parsed.AgentKey) {

      propertyGetNext.mutate(
        { query: nextLink, key: parsed.AgentKey },
        {
          onSuccess: (val) => {
            const temp = val.data.value;
            setListings((old) => [...old, ...temp]);
            setNextLink(val.data["@odata.nextLink"]);
          },
          onError: (er) => {
            console.log("error =>>>>", er);
          },
        }
      );
    }
  }

  return (
    <div className={Style.container}>
      <h2 className={Style.title}>Latest properties</h2>
      <div>
        <InfiniteScroll
          dataLength={listings.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          loader={
            <div className={Style.spinnerContainer}>
              <BeatLoader color="#151aae" />
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>You have seen it all!</b>
            </p>
          }
        >
          {listings.map((listing) => {
            return (
              <Listing
                AgentInfo={AgentInfo}
                AgentKey={AgentKey}
                listing={listing}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export { LatestListings };
