import Style from "./Listing.module.css";
import PlaceholderImage from "../../../assets/images/placeholder.png";
import { formatNumber, stringShorter } from "../../../core/utils/helpers.util";
import BedroomIcon from "../../../assets/svg/bedroom-social.svg";
import ShowerIcon from "../../../assets/svg/shower-social.svg";
import AreaIcon from "../../../assets/svg/area-social.svg";
import { useCreateShortLink } from "../../../core/api/share.api";

const Listing = ({ listing, AgentKey, AgentInfo }) => {
  const createLinkMutation = useCreateShortLink();

  const generateQrCode = async ({
    AgentKey,
    ListingKey,
    UnparsedAddress,
    first_name,
    last_name,
    ListPrice,
    PublicRemarks,
    Media,
  }) => {
    const obj = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://rfreso.page.link",
        link: `https://app.realtyfeed.com/agent?AgentKey=${AgentKey}&ListingKey=${ListingKey}`,
        androidInfo: {
          androidPackageName: "com.realtyfeed",
        },
        iosInfo: {
          iosBundleId: "org.reactjs.native.example.RealtyFeed",
          iosAppStoreId: "1624878593",
        },
        socialMetaTagInfo: {
          socialTitle: UnparsedAddress,
          socialDescription: `${UnparsedAddress ? UnparsedAddress : ""} 
${first_name} ${last_name}
${formatNumber(ListPrice ? ListPrice.toString() : "")}$
${stringShorter(PublicRemarks, 200)}`,
          socialImageLink: Media ? Media : null,
        },
      },
      suffix: {
        option: "SHORT",
      },
    };

    createLinkMutation.mutate(obj, {
      onSuccess: async (val) => {
        if (val && val.data && val.data.shortLink) {
          console.log("---link-- ", val.data.shortLink);
          window.open(val.data.shortLink, "_blank");
        }
      },
    });
  };

  return (
    <div
      className={Style.container}
      onClick={() =>
        generateQrCode({
          ...listing,
          ...AgentInfo,
          Media:
            listing &&
            listing.Media &&
            listing.Media &&
            listing.Media.length > 0
              ? listing.Media[0].MediaURL
              : null,
          AgentKey: AgentKey,
        })
      }
    >
      {listing && listing.Media && listing.Media && listing.Media.length > 0 ? (
        <img
          className={Style.listingImage}
          src={
            listing.Media[0].Thumbnail === null
              ? listing.Media[0].MediaURL
              : listing.Media[0].Thumbnail === ""
              ? PlaceholderImage
              : listing.Media[0].Thumbnail
          }
          // src={listing.Media[0].MediaURL}
          alt="property"
        />
      ) : (
        <img
          className={Style.listingImage}
          src={PlaceholderImage}
          alt="property"
        />
      )}

      <p className={Style.unparsedAddress}>{listing.UnparsedAddress}</p>
      <p className={Style.publicRemarks}>
        {stringShorter(listing.PublicRemarks, 40)}
      </p>
      <div className={Style.iconsContainer}>
        {listing.PropertyType &&
          listing.PropertyType !== "Land" &&
          listing.PropertyType !== "Farm" && (
            <>
              <img src={BedroomIcon} alt="icon" />
              <span style={{ marginLeft: "8px" }}>{listing.BedroomsTotal}</span>
            </>
          )}

        {listing.PropertyType &&
          listing.PropertyType !== "Land" &&
          listing.PropertyType !== "Farm" && (
            <>
              <img style={{ marginLeft: "30px" }} src={ShowerIcon} alt="icon" />
              <span style={{ marginLeft: "8px" }}>
                {listing.BathroomsTotalInteger}
              </span>
            </>
          )}

        {listing.BuildingAreaTotal && (
          <>
            <img src={AreaIcon} style={{ marginLeft: "30px" }} alt="icon" />
            <span style={{ marginLeft: "8px" }}>
              {listing.BuildingAreaTotal}
            </span>
            {listing.BuildingAreaUnits && (
              <span> {listing.BuildingAreaUnits}</span>
            )}
          </>
        )}

        {listing.PropertyType &&
          listing.PropertyType !== "Land" &&
          listing.PropertyType !== "Farm" && (
            <span style={{ marginLeft: "15px", marginRight: "8px" }}>|</span>
          )}

        <span style={{}}>{listing.PropertyType}</span>
      </div>
      <p className={Style.listPrice}>
        {formatNumber(listing.ListPrice.toString()) + "$"}
      </p>
    </div>
  );
};

export { Listing };
