import { useEffect, useState } from "react";
import { ListingCarousel } from "../../common/ListingCarousel/ListingCarousel";
import { QueryBuilder } from "odata-query-builder";

import Style from "./NearbyContainer.module.css";
import { usePropertyListView } from "../../../core/api/property.api";

const NearbyContainer = ({ agentKey, propertyDetail, data, listings, setListings }) => {
  const propertyMutation = usePropertyListView();

  useEffect(() => {
    const query = `?$select=Media,ListingKey,UnparsedAddress,Coordinates,ListPrice,PropertyType,BedroomsTotal,BathroomsTotalInteger,BuildingAreaTotal,PublicRemarks,InternetAddressDisplayYN&$filter=geo.distance(Coordinates, POINT(${propertyDetail.Longitude} ${propertyDetail.Latitude})) lt 0.02857142857142857`;

    propertyMutation.mutate(
      { query: query, key: agentKey },
      {
        onSuccess: (val) => {
          const temp = val.data.value;
          // console.log('--temp -- ', temp[0]);
          let currentPropertyRemoved = temp.filter(
            (row) => row.ListingKey !== propertyDetail.ListingKey
          );

          setListings(currentPropertyRemoved);
        },
        onError: (er) => {
          console.log("error =>>>>", er);
        },
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <h2 style={{ textAlign: "left" }}>Nearby listings</h2>
      <div className={Style.nearbyContainer} >
        <ListingCarousel data={listings} agentKey={agentKey} />
      </div>
    </>
  );
};

export { NearbyContainer };
