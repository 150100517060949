import { useCookies } from 'react-cookie';
import React, { useState } from "react";
import Style from './AcceptContainer.module.css';
import Icon from '../../assets/Group9007.svg'
import { Scrollbars } from 'react-custom-scrollbars';
import { CustomButton } from '../common/CustomButton/CustomButton';

const AcceptContainer = () => {
    const [cookies] = useCookies(['rfak'] );
    const [checkboxChecked , setCheckboxChecked] = useState(false)

    return (
      <div className={Style.container}>
        <div className={Style.content}>
          <div className={Style.titleContainer}>
            <img src={Icon} alt="icon" />
            <p className={Style.headerTitle}>Terms & Conditions</p>
          </div>

          <Scrollbars
            style={{ height: "400px" }}
            // renderTrackHorizontal={(props) => (
            //   <div
            //     {...props}
            //     style={{ background: "red" }}
            //     className="track-horizontal"
            //   />
            // )}
            renderTrackVertical={(props) => (
              <div
                {...props}
                className="track-vertical"
                style={{ background: "#ccc", width: "4px", height: "100%", float: 'right' }}
              />
            )}
            // renderThumbHorizontal={(props) => (
            //   <div
            //     {...props}
            //     style={{ background: "red" }}
            //     className="thumb-horizontal"
            //   />
            // )}
            renderThumbVertical={(props) => (
              <div
                {...props}
                style={{ background: "#151aae" }}
                className="thumb-vertical"
              />
            )}
            className={Style.termsAndConditionsContainer}
          >
            <div
              className={Style.scrollBar}
              style={{ paddingRight: "0px 10px", textAlign: "left" }}
            >
              <h2 style={{ fontFamily: "montserrat" }}>RealtyFeed</h2>
              <p> 1. Terms</p>
              <p>
                {" "}
                Company:{" "}
                <span>
                  {" "}
                  Realtyna Inc. and all its subsidiaries and affiliates.
                </span>
              </p>
              <p>
                RealtyFeed:{" "}
                <span>
                  {" "}
                  RealtyFeed is a cloud platform 100% owned and operated by
                  Realtyna Inc. Under this agreement, Realtyna/RealtyFeed may
                  refer to itself as “us” and “we,” and “our.”
                </span>
              </p>
              <p>
                Client / Customer:{" "}
                <span>
                  Any person or business that subscribes to or purchases our
                  products and services.
                </span>
              </p>
              <p>
                User:{" "}
                <span>
                  {" "}
                  Any person or business that downloads and utilizes our
                  products and services directly or indirectly. In some cases,
                  the clients of our clients need to subscribe to our system to
                  interact with their respective agent/broker (our direct
                  clients). In this case, these users will be considered our
                  users/end users. In any case, we are transparent that these
                  services are provided (powered) by Realtyna, and our terms of
                  services and privacy policy are always accessible throughout
                  our applications.
                </span>
              </p>
              <p>
                2. Agreement{" "}
                <span>
                  Subscribing, Signing up, and/or using Realtyna/RealtyFeed
                  products and services in any way means you agree with the
                  terms and conditions and privacy policy defined by us. You can
                  terminate your agreement with us anytime via canceling
                  Realtyna/RealtyFeed services or deleting and not using our
                  products. You are bound to the terms and conditions as long as
                  you keep using Realtyna/RealtyFeed products and services.
                </span>
              </p>

              <p>
                2.1. Updates{" "}
                <span>
                  We might update our terms and conditions and other relevant
                  policies from time to time. In this case, you can find the
                  updated information in the Terms and Conditions and/or Privacy
                  Policy or other relevant sections. If you don’t agree with any
                  new changes, feel free to deactivate your account at any time.
                  However, the new terms and conditions will apply if you don’t
                  deactivate your account.
                </span>
              </p>
              <p>
                3. The Services We Provide{" "}
                <span>
                  The mission of Realtyna/RealtyFeed is to develop and provide
                  technologically advanced products and services to the real
                  estate industry at an affordable cost. In a nutshell, our core
                  activities and goals can be defined as: Real Estate data
                  aggregation and consolidation by working closely with MLS
                  providers and Real Estate Associations, boosting privacy and
                  data protection, providing relevant content through AI and
                  Machine Learning, lead generation, providing quality
                  applications for real estate professionals, etc.
                </span>
              </p>
            </div>
          </Scrollbars>
          <div className={Style.checkboxContainer}>
            <input
              type={"checkbox"}
              checked={checkboxChecked}
              onChange={(e) => setCheckboxChecked(e.target.checked)}
            />
            <span
              onClick={() => {
                setCheckboxChecked((old) => !old);
              }}
              style={{ fontSize: "14px", marginLeft: "10px" }}
            >
              I agree with the Terms and Conditions
            </span>
          </div>
          <div
            className={Style.checkboxContainer}
            style={{ marginTop: "30px" }}
          >
            <CustomButton
              onClick={() => {
                if (checkboxChecked) {
                  window.location.href = "realtyfeed://" + cookies.rfak;
                }
              }}
              text="Continue"
            />
          </div>
        </div>
      </div>
      //   <a
      //     style={{ textDecoration: "none", marginBottom: "30px" }}
      //     onClick={() => {}}
      //     href={"realtyfeed://" + cookies.rfak}
      //   >
      //     Accept
      //   </a>
    );
}


const styles = {

}

export { AcceptContainer }