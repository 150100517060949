import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useProfileLoader,
  usePropertyDetails,
} from "../../core/api/property.api";
import { CurrencyMask } from "../../core/utils/helpers.util";
import { AboutThisHome } from "./AboutThisHome/AboutThisHome";
import { AgentInfo } from "./AgentInfo/AgentInfo";
import { FactsAndFeatures } from "./FactsAndFeatures/FactsAndFeatures";
import { ImagesContainer } from "./ImagesContainer/ImagesContainer";
import { KeyDetails } from "./KeyDetails/KeyDetails";
import { ListingAgent } from "./ListingAgent/ListingAgent";
import {ListingDetailsSkeletonLoading} from "./ListingDetailsSkeletonLoading/ListingDetailsSkeletonLoading";
import Style from "./ListingDetailsContainer.module.css";
import { ListingInfo } from "./ListingInfo/ListingInfo";
import { ListingLocation } from "./ListingLocation/ListingLocation";
import { ListingOffice } from "./ListingOffice/ListingOffice";
import { NearbyContainer } from "./NearbyContainer/NearbyContainer";
import { ShareContainer } from "./ShareContainer/ShareContainer";
import RFLOGO from '../../assets/svg/rflogo.svg'
import RFTEXT from '../../assets/svg/rftext.svg'
const queryString = require("query-string");

const ListingDetailsContainer = ({}) => {
  const [listings, setListings] = useState([]);

  let data = [];

  const [images, setImages] = useState([]);
  const [listing, setListing] = useState(null);
  const [agentInfo, setAgentInfo] = useState(null);

  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const getPropertyMutation = usePropertyDetails();
  const getAgentProfile = useProfileLoader();
  const { search } = useLocation();

  // useEffect(() => {
  //   // search query string changed
  // }, [search]);

  if (parsed && parsed.SourceRedirect && parsed.ListingKey) {
    window.location.href =
      parsed.SourceRedirect + "/listings/details/" + parsed.ListingKey + "/";
  }

  useEffect(() => {
    if (parsed && parsed.SourceRedirect && parsed.ListingKey) {
      window.location.href =
        parsed.SourceRedirect + "/listings/details/" + parsed.ListingKey + "/";
    } else {
      if (parsed && parsed.AgentKey && parsed.ListingKey) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        getPropertyMutation.mutate(
          { key: parsed.AgentKey, ListingKey: parsed.ListingKey },
          {
            onSuccess: (val) => {
              console.log("--val--", val);
              let images = [];
              if (val && val.data) {
                if (val.data.Media) {
                  val.data.Media.forEach((row) => {
                    images.push(row.MediaURL);
                  });
                }
                setImages(images);
                setListing(val.data);
              }
            },
          }
        );
        getAgentProfile.mutate(parsed.AgentKey, {
          onSuccess: (val) => {
            if (val && val.data) {
              setAgentInfo(val.data);
            }
          },
        });
      }
    }
  }, [search]);

  return (
    <div className={Style.container}>
      <header className={Style.header}>
        <div className={Style.headerContent}>
          <img src={RFLOGO} alt="logo" />
          <img style={{ marginLeft: "15px" }} src={RFTEXT} alt="logo text" />
        </div>
      </header>

      <div className={Style.mainContainer}>
        {getPropertyMutation.isLoading || getAgentProfile.isLoading ? (
          <ListingDetailsSkeletonLoading />
        ) : (
          listing &&
          agentInfo && (
            <div className={Style.content}>
              <h2 style={{ textAlign: "left" }}>Property Details</h2>
              <ImagesContainer images={images} />
              <div className={Style.twoCol}>
                <div className={Style.left}>
                  <ListingInfo
                    ListPrice={CurrencyMask(listing.ListPrice) + "$"}
                    UnparssedAddress={listing.UnparsedAddress}
                    listing={listing}
                  />
                  <AboutThisHome text={listing.PublicRemarks} />
                  <KeyDetails propertyDetail={listing} />
                  <FactsAndFeatures propertyDetail={listing} />
                  <ListingLocation
                    agentKey={parsed.AgentKey}
                    listings={listings}
                    Latitude={listing.Latitude}
                    Longitude={listing.Longitude}
                  />
                </div>
                <div className={Style.right}>
                  <AgentInfo
                    profileImage={agentInfo.profile_picture}
                    agentDescription={`${agentInfo.user_professions},
                    ${agentInfo.full_address}
                    `}
                    agentEmail={agentInfo.email}
                    agentName={agentInfo.first_name + " " + agentInfo.last_name}
                    agentPhone={agentInfo.personal_phone}
                    agentTelephone={agentInfo.professional_phone}
                  />
                  <ShareContainer
                    AgentKey={parsed.AgentKey}
                    ListingKey={parsed.ListingKey}
                    UnparsedAddress={listing.UnparsedAddress}
                    first_name={agentInfo.first_name}
                    last_name={agentInfo.last_name}
                    ListPrice={listing.ListPrice}
                    PublicRemarks={listing.PublicRemarks}
                    Media={
                      listing.Media
                        ? listing.Media[0]
                          ? listing.Media[0].MediaURL
                          : null
                        : null
                    }
                  />
                  {/* <ListingOffice
                    officeName={listing.ListOfficeName}
                    officePhone={listing.ListOfficePhone}
                    officeTelephone={listing.ListOfficePhone}
                    officeEmail={listing.ListOfficeEmail}
                  />
                  <ListingAgent
                    agentEmail={listing.ListAgentEmail}
                    agentName={listing.ListAgentFullName}
                    agentPhone={listing.ListAgentMobilePhone}
                    agentTelephone={listing.ListAgentPreferredPhone}
                  /> */}
                </div>
              </div>
              <NearbyContainer
                listings={listings}
                setListings={setListings}
                agentKey={parsed.AgentKey}
                propertyDetail={listing}
                data={data}
              />
            </div>
          )
        )}
      </div>

      <footer className={Style.footer}>
        <div className={Style.footerContent}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <a className={Style.footerLink} href="/">
              {" "}
              About us{" "}
            </a>
            <p style={{ marginLeft: "8px" }} className={Style.footerLink}>
              |
            </p>
            <a
              style={{ marginLeft: "8px" }}
              className={Style.footerLink}
              href="/"
            >
              {" "}
              Help{" "}
            </a>
            <p style={{ marginLeft: "8px" }} className={Style.footerLink}>
              |
            </p>
            <a
              style={{ marginLeft: "8px" }}
              className={Style.footerLink}
              href="https://realtyna.com/reso-web-api-app-terms-and-conditions-and-privacy-policy/"
            >
              {" "}
              Terms and Conditions{" "}
            </a>
          </div>
          <p
            style={{
              fontSize: "16px",
              color: "#17171a",
              fontWeight: 300,
              fontFamily: "montserratLight",
              textAlign: "center",
            }}
          >
            Copyright © 2022 Realtyna® Inc.
          </p>
          <div style={{ width: "323px" }}></div>
        </div>
      </footer>
    </div>
  );
};

export { ListingDetailsContainer };
