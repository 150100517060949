import Style from './AgentView.module.css';

const AgentView = ({agentName , agentDescription, agentImage}) => {
    
    return (
      <>
        <div className={Style.contentContainer}>
          <img className={Style.agentImage} src={agentImage} alt={agentName} />
          <div style={{ paddingLeft: "15px" }}>
            <p className={Style.agentName}>{agentName}</p>
            <p className={Style.agentDescription}>{agentDescription}</p>
          </div>
        </div>
      </>
    );
}



export { AgentView }