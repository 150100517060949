import logo from './logo.svg';
import { useState } from 'react';
import './App.css';
import axios from 'axios'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  useParams,
} from "react-router-dom";
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { AcceptContainer } from './components/AcceptContainer/AcceptContainer';
import { ListingDetailsContainer } from './components/ListingDetailsContainer/ListingDetailsContainer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScrollToTop from './components/Layout/ScrollToTop';
import { BioContainer } from './components/BioContainer/BioContainer';


function CustomLink() {
  const { agent_key } =  useParams()
  const [ IframeUrl , setIframeUrl ] =  useState('')

  const [cookies, setCookie] = useCookies(['rfak']);

  setCookie("rfak", agent_key, { maxAge: 3600 * 72, path: "/" });

  const getIpAddress = async () =>
  await axios.get('https://geolocation-db.com/json/');

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  // useEffect(() => {
  //   window.open("realtyfeed://" + agent_key);

  //   if (getMobileOperatingSystem() === "Android") {
  //     setTimeout(function () {
  //       window.location =
  //         "https://play.google.com/store/apps/details?id=com.realtyfeed&referrer=utm_source%3D" +
  //         agent_key;
  //     }, 1000);
  //   } else if (getMobileOperatingSystem() === "iOS") {
  //     setTimeout(function () {
  //       window.location =
  //         "https://apps.apple.com/us/app/realtyfeed/id1624878593";
  //     }, 1000);
  //   }
  // });
  const getData = async () => {
    // const ip = await getIpAddress();
    // console.log();
    // alert(
    //   JSON.stringify({
    //     ip: ip.data.IPv4,
    //     language: navigator.language,
    //     width: window.screen.width,
    //     height: window.screen.height,
    //   })
    // );
  }
  useEffect(() => {
    getData()
  },[])

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection:'column'
        }}
      >
        <p>
          <a
            style={{
              color: "#555",
              textDecoration: "none",
              marginBottom: "30px",
            }}
            onClick={() => {}}
            href={"realtyfeed://" + agent_key}
          >
            Open the app
          </a>
        </p>
        {getMobileOperatingSystem() === "Android" && (
          <a
            style={{ color: "#555", textDecoration: "none" }}
            href={
              "https://play.google.com/store/apps/details?id=com.realtyfeed&referrer=utm_source%3D" +
              agent_key
            }
          >
            GET IT ON GOOGLE PLAY
          </a>
        )}
        {getMobileOperatingSystem() === "iOS" && (
          <a
            style={{ color: "#555", textDecoration: "none" }}
            href={"https://apps.apple.com/us/app/realtyfeed/id1624878593"}
          >
            DOWNLOAD ON THE APP STORE
          </a>
        )}
        {/* {
        <iframe
          id="appIframe"
          src={"realtyfeed://" + agent_key}
          width={0}
          style={{ visibility: "hidden" }}
          height={0}
          title="app"
        />
      } */}
      </div>
    </>
  );
}

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <header className="App-header">
          <BrowserRouter>
              <ScrollToTop/>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Outlet />
                  </>
                }
              >
                <Route
                  path="/Agent/:agent_key"
                  element={<CustomLink />}
                ></Route>
                <Route
                  path="/Agent"
                  element={<ListingDetailsContainer />}
                ></Route>
                <Route
                  path="/Bio"
                  element={<BioContainer />}
                ></Route>
                <Route path="/Accept" element={<AcceptContainer />}></Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </header>
      </QueryClientProvider>
    </div>
  );
}

export default App;
