import Style from "./InfoItemKeyValue.module.css";

const InfoItemKeyValue = ({
  keyTitle,
  value,
  icon,
  containerStyle,
  iconWidth,
  iconHeight,
  color,
}) => {
  return (
    <div
      className={Style.container}
      style={{
        ...containerStyle,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        // justifyContent: "center",
      }}
    >
      <div
        style={{
          width: `${iconWidth}px`,
          height: `${iconHeight}px`,
          // border: "1px solid black",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {icon}
      </div>
      <p style={color ? { color: color } : {}} className={Style.text}>
        <span className={Style.key}>{keyTitle}</span>
        <span className={Style.value}>{value}</span>
      </p>
    </div>
  );
};

export { InfoItemKeyValue };
