import { Helmet } from "react-helmet";
import Style from "./AgentInfo.module.css";

const AgentInfo = ({AgentInfo}) => {
    console.log("--agentinf--", AgentInfo);
  return (
    <div className={Style.container}>
      <Helmet>
        <title>{AgentInfo.first_name + " " + AgentInfo.last_name}</title>
        <meta name="description" content={AgentInfo.bio} />
      </Helmet>
      <img
        className={Style.image}
        src={AgentInfo.profile_picture}
        alt={AgentInfo.first_name + " " + AgentInfo.last_name}
      />
      <div className={Style.rightCol}>
        <h2 className={Style.fullName}>
          {AgentInfo.first_name + " " + AgentInfo.last_name}
        </h2>
        <p className={Style.bio}>{AgentInfo.bio}</p>
      </div>
    </div>
  );
};

export { AgentInfo };
