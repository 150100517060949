import { useState } from "react";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";
import { useProfileLoader, usePropertyListView } from "../../core/api/property.api";
import { AgentInfo } from "./AgentInfo/AgentInfo";
import Style from "./BioContainer.module.css";
import { LatestListings } from "./LatestListings/LatestListings";
import { SocialLinks } from "./SocialLinks/SocialLinks";
const queryString = require("query-string");

const BioContainer = ({}) => {

  const [agentInfo, setAgentInfo] = useState(null);


 
  const getAgentProfile = useProfileLoader();

  const location = useLocation();
  const parsed = queryString.parse(location.search);
 

  useEffect(() => {
    if (parsed && parsed.AgentKey) {
      getAgentProfile.mutate(parsed.AgentKey, {
        onSuccess: (val) => {
          if (val && val.data) {
            setAgentInfo(val.data);
          }
        },
      });
    }
  },[])


  return (
    <div className={Style.container}>
      {getAgentProfile.isLoading || !agentInfo ? (
        <Skeleton style={{ marginTop: "25px" }} height={"90px"} count={1} />
      ) : (
        <AgentInfo AgentInfo={agentInfo} />
      )}
      <SocialLinks AgentInfo={agentInfo} AgentKey={parsed.AgentKey} />
      <LatestListings AgentInfo={agentInfo} AgentKey={parsed.AgentKey} />
    </div>
  );
};

export { BioContainer };
