import { KeyValue } from "../../common/KeyValue/KeyValue";
import { SectionTitle } from "../../common/SectionTitle/SectionTitle";
import Style from "./KeyDetails.module.css";

const KeyDetails = ({ onClick, text, propertyDetail }) => {
  return (
    <>
      <div className={Style.container}>
        <SectionTitle text="Key Details" />
        <div className={Style.contentContainer}>

          {propertyDetail && propertyDetail.CumulativeDaysOnMarket ? (
            <KeyValue
              keyTitle={"On Realtyna:"}
              value={propertyDetail.CumulativeDaysOnMarket}
            />
          ) : null}
          {propertyDetail.ListOfficeMlsId ? (
            <KeyValue
              keyTitle={"#MLS:"}
              value={propertyDetail.ListOfficeMlsId}
            />
          ) : null}
          {propertyDetail.CumulativeDaysOn ? (
            <KeyValue
              keyTitle={"Price / sqm:"}
              value={propertyDetail.CumulativeDaysOn}
            />
          ) : null}
          {propertyDetail.YearBuilt ? (
            <KeyValue
              keyTitle={"Year Built:"}
              value={propertyDetail.YearBuilt}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export { KeyDetails };
