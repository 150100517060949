import {
  CurrencyMask,
  getAreaUnit,
  stringShorter,
} from "../../../core/utils/helpers.util";
import Style from "./CarouselItem.module.css";
import placeholder from "../../../assets/images/placeholder.png";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

const CarouselItem = ({ listing, agentKey, dragging }) => {
  const navigate = useNavigate();
  console.log('listing--',listing.ListingKey);

  const handleOnItemClick = useCallback(
    (e) => {
      console.log("handleOnItemClick");
      if (dragging) e.stopPropagation();
    },
    [dragging]
  );

  return (
    <div
      className={Style.container}
      onClickCapture={handleOnItemClick}
      onClick={() => {
        // window.location.replace(`/agent?AgentKey=${agentKey}&ListingKey=${listing.ListingKey}`)
        navigate(
          `/agent?AgentKey=${agentKey}&ListingKey=${listing.ListingKey}`,
          { replace: true }
        );
      }}
    >
      <img
        className={Style.image}
        src={
          listing.Media
            ? listing.Media.length > 0
              ? listing.Media[0].MediaURL
              : placeholder
            : placeholder
        }
        alt={listing.UnparssedAddress}
      />
      <p className={Style.UnparssedAddress}>{listing.UnparssedAddress}</p>
      <p className={Style.PublicRemarks}>
        {stringShorter(listing.PublicRemarks, 40)}
      </p>
      <div className={Style.iconContainer}>
        {listing.PropertyType !== "Farm" && listing.PropertyType !== "Land" ? (
          <>
            {listing.BedroomsTotal ? (
              <>
                <div className={Style.bedroomIcon}></div>
                <p style={{ marginLeft: "8px" }}>{listing.BedroomsTotal}</p>
              </>
            ) : null}
            {listing.BathroomsTotalInteger ? (
              <>
                <div className={Style.bathroomsIcon}></div>
                <p style={{ marginLeft: "8px" }}>
                  {listing.BathroomsTotalInteger}
                </p>
              </>
            ) : null}
          </>
        ) : null}
        {listing.BuildingAreaTotal ? (
          <>
            <div className={Style.buildingAreaIcon}></div>
            <p style={{ marginLeft: "8px" }}>
              {`${stringShorter(
                listing.BuildingAreaTotal
                  ? listing.BuildingAreaTotal.toString()
                  : "",
                5
              )} ${getAreaUnit(listing.BuildingAreaUnits)}`}
              {/* {listing.BuildingAreaTotal + " " + listing.BuildingAreaUnits} */}
            </p>
            <p style={{ marginLeft: "4px" }}>|</p>
          </>
        ) : null}
        <p style={{ marginLeft: listing.BuildingAreaTotal ? "2px" : "10px" }}>
          {listing.PropertyType}
        </p>
      </div>
      <p className={Style.ListPrice}>{CurrencyMask(listing.ListPrice) + "$"}</p>
    </div>
  );
};

export { CarouselItem };
