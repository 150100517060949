export function CurrencyMask(Number) {
  Number += "";
  Number = Number.replace(",", "");
  Number = Number.replace(",", "");
  Number = Number.replace(",", "");
  Number = Number.replace(",", "");
  Number = Number.replace(",", "");
  Number = Number.replace(",", "");
  const x = Number.split(".");
  let y = x[0];
  const z = x.length > 1 ? "." + x[1] : "";
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(y)) y = y.replace(rgx, "$1" + "," + "$2");
  return y + z;
}

export const formatNumber = (n) => {
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const stringShorter = (text, size) => {
  let newString =
    text && text.length > size ? text.slice(0, size) + "..." : text;
  return newString;
};

export const getAreaUnit = (unit) => {
  switch (unit) {
    case 'Square Feet':
      return 'sqft';
    case 'Square Meteres':
      return 'M2';
    case 'Acres':
      return 'acres';
    default:
      return '';
  }
}