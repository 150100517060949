import { InfoItem } from "../../common/InfoItem/InfoItem";
import { InfoItemKeyValue } from "../../common/InfoItemKeyValue/InfoItemKeyValue";
import { SectionTitle } from "../../common/SectionTitle/SectionTitle";
import Style from "./FactsAndFeatures.module.css";

const FactsAndFeatures = ({ propertyDetail }) => {
  return (
    <>
      <div className={Style.container}>
        <SectionTitle text="Facts & Features" />
        <div style={{ marginTop: "5px" }} className={Style.itemContainer}>


          
          {propertyDetail.PropertyType ? (
            <InfoItemKeyValue
              containerStyle={{ }}
              keyTitle={"Property Type:"}
              value={propertyDetail.PropertyType}
              iconHeight={28}
              iconWidth={31}
              icon={
                <img
                  src={require("../../../assets/svg/Type.svg").default}
                  alt={"proppertyType"}
                />
              }
            />
          ) : null}
          {propertyDetail.Stories ? (
            <InfoItemKeyValue
              containerStyle={{}}
              keyTitle={"Floors:"}
              value={propertyDetail.Stories}
              iconHeight={28}
              iconWidth={31}
              icon={
                <img
                  src={require("../../../assets/svg/floor_area.svg").default}
                  alt={"floor"}
                />
              }
            />
          ) : null}
          {propertyDetail.YearBuilt ?<InfoItemKeyValue
            containerStyle={{}}
            keyTitle={"Year Build:"}
            value={propertyDetail.YearBuilt}
            iconHeight={28}
            iconWidth={31}
            icon={
              <img
                src={require("../../../assets/svg/House.svg").default}
                alt={"House"}
              />
            }
          />:null}

          {propertyDetail.YearBuiltEffective ? <InfoItemKeyValue
            keyTitle={"Year Renovated:"}
            value={propertyDetail.YearBuiltEffective}
            iconHeight={28}
            iconWidth={31}
            icon={
              <img
                src={require("../../../assets/svg/yearBuild.svg").default}
                alt={"House"}
              />
            }
          />:null}
          {propertyDetail.ListOfficeMlsId ?<InfoItemKeyValue
            keyTitle={"MLS id:"}
            value={propertyDetail.ListOfficeMlsId}
            iconHeight={28}
            iconWidth={31}
            icon={
              <img
                src={require("../../../assets/svg/MLS.svg").default}
                alt={"MLS"}
              />
            }
          />:null}
        </div>
      </div>
    </>
  );
};

export { FactsAndFeatures };
