import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

const MainUrl = 'https://api.realtyfeed.com/reso/odata';

const instance = axios.create({
  baseURL: MainUrl,
});

// instance.interceptors.request.use(function (config: any) {
 
//   config.metadata = { startTime: new Date()}
//   console.log('-- url: ', config.url);
//   console.log('-- header: ', config.headers);
  
//   return config;
// }, function (error) {
//   return Promise.reject(error);
// });

// instance.interceptors.response.use(
//   function (response: any) {
//     response.config.metadata.endTime = new Date();
//     response.duration =
//       response.config.metadata.endTime - response.config.metadata.startTime;
//       console.log('--- ms :', response.duration);
      
//     return response;
//   },
//   function (error) {
//     error.config.metadata.endTime = new Date();
//     error.duration =
//       error.config.metadata.endTime - error.config.metadata.startTime;
//     return Promise.reject(error);
//   },
// );

export const Http = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
};
