import { formatNumber } from "../../../core/utils/helpers.util";
import { InfoItem } from "../../common/InfoItem/InfoItem";
import Style from "./ListingInfo.module.css";

const ListingInfo = ({ ListPrice, UnparssedAddress, listing }) => {
  return (
    <>
      <div className={Style.container}>
        <p className={Style.listPrice}>{ListPrice}</p>
        <p className={Style.UnparssedAddress}>{UnparssedAddress}</p>
        <div style={{ marginTop: "37px" }} className={Style.itemContainer}>
          {listing.PropertyType && (
            <InfoItem
              containerStyle={{ flexGrow: 1, width: "33%" }}
              iconHeight={28}
              iconWidth={31}
              text={listing.PropertyType}
              icon={<img src={require('../../../assets/svg/apartment.svg').default} alt={'apartment'} />}
            />
          )}

          {listing.PropertyType !== "Farm" &&
          listing.PropertyType !== "Land" ? (
            <>
              {listing.BedroomsTotal ? (
                <InfoItem
                  containerStyle={{ flexGrow: 1, width: "33%" }}
                  iconHeight={28}
                  iconWidth={31}
                  text={
                    listing.BedroomsTotal
                      ? listing.BedroomsTotal
                      : 0 + " Bedrooms"
                  }
                  icon={<img src={require('../../../assets/svg/bed.svg').default} alt={'bedrooms'} />}

                />
              ) : null}
              {listing.BathroomsTotalInteger ? (
                <InfoItem
                  containerStyle={{ flexGrow: 1, width: "33%" }}
                  iconHeight={28}
                  iconWidth={31}
                  text={
                    listing.BathroomsTotalInteger
                      ? listing.BathroomsTotalInteger
                      : 0 + " Bathrooms"
                  }
                  icon={<img src={require('../../../assets/svg/bath.svg').default} alt={'bathrooms'} />}

                />
              ) : null}
            </>
          ) : null}
          {listing.LivingArea ? (
            <InfoItem
              containerStyle={{ flexGrow: 1, width: "33%", marginTop: "25px" }}
              iconHeight={28}
              iconWidth={31}
              text={
                listing.LivingArea
                  ? `${formatNumber(listing.LivingArea.toString())} ${
                      listing.LivingAreaUnits ? listing.LivingAreaUnits : ""
                    } floor area`
                  : ""
              }
              icon={<img src={require('../../../assets/svg/floor_area.svg').default} alt={'floor_area'} />}

            />
          ) : null}
          {listing.BuildingAreaTotal ? (
            <InfoItem
              containerStyle={{ flexGrow: 1, width: "33%", marginTop: "25px" }}
              iconHeight={28}
              iconWidth={31}
              text={
                listing.BuildingAreaTotal
                  ? `${formatNumber(listing.BuildingAreaTotal.toString())} ${
                      listing.BuildingAreaUnits ? listing.BuildingAreaUnits : ""
                    } land area`
                  : ""
              }
              icon={<img src={require('../../../assets/svg/land.svg').default} alt={'land'} />}

            />
          ) : null}
          { listing.GarageSpaces? <InfoItem
            containerStyle={{ flexGrow: 1, width: "33%", marginTop: "25px" }}
            iconHeight={28}
            iconWidth={31}
            text={`${
              listing.GarageSpaces
                ? listing.GarageSpaces
                : '0'
            } Garages`}
            icon={<img src={require('../../../assets/svg/garage.svg').default} alt={'garage'} />}

          />:null}
        </div>
      </div>
    </>
  );
};

export { ListingInfo };
