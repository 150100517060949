import { useLocation } from "react-router-dom";
import Style from "./SocialLinks.module.css";
import YoutubeLogo from '../../../assets/svg/youtube-social.svg'
import RealtyFeedLogo from '../../../assets/svg/realtyfeed-social.svg'
import TwitterLogo from '../../../assets/svg/twitter-social.svg'
import FacebookLogo from '../../../assets/svg/facebook-social.svg'
import MLSLogo from '../../../assets/svg/mls-social.svg'
import { useCreateShortLink } from "../../../core/api/share.api";
import { BounceLoader } from "react-spinners";
import { useEffect, useState } from "react";
import { logDOM } from "@testing-library/react";

const queryString = require("query-string");

const SocialLinks = ({AgentKey, AgentInfo}) => {

  const [RFDLink, setRFDLink] = useState("");
  const createLinkMutation = useCreateShortLink()

  const generateQrCode = async ({
    AgentKey,
    first_name,
    last_name,
    agent_bio
  }) => {
    const obj = {
      dynamicLinkInfo: {
        domainUriPrefix: "https://rfreso.page.link",
        link: `https://app.realtyfeed.com/agent?AgentKey=${AgentKey}`,
        androidInfo: {
          androidPackageName: "com.realtyfeed",
        },
        iosInfo: {
          iosBundleId: "org.reactjs.native.example.RealtyFeed",
          iosAppStoreId: "1624878593",
        },
        socialMetaTagInfo: {
          socialTitle: first_name + " " + last_name,
          socialDescription: `${agent_bio}`,
        },
      },
      suffix: {
        option: "SHORT",
      },
    };

    createLinkMutation.mutate(obj, {
      onSuccess: async (val) => {
        if (val && val.data && val.data.shortLink) {
          window.open(val.data.shortLink, "_blank");
        }
      },
    });
  }


  const location = useLocation();
  const parsed = queryString.parse(location.search);
    
  return (
    <div className={Style.container}>
      {createLinkMutation.isLoading ? (
        <BounceLoader size={53} color="#151aae" />
      ) : (
        <a
          href="/"
          onClick={async (e) => {
            e.preventDefault();

            await generateQrCode({ AgentKey: AgentKey, ...AgentInfo });
          }}
        >
          <div className={Style.socialLinkContainer}>
            <div className={Style.socialLogo}>
              <img alt="logo" src={RealtyFeedLogo} />
            </div>
            <p className={Style.socialLinkTitle}>RealtyFeed</p>
          </div>
        </a>
      )}

      {parsed && parsed.Youtube && (
        <a className={Style.marginLeft} href={parsed.Youtube}>
          <div className={Style.socialLinkContainer}>
            <div className={Style.socialLogo}>
              <img alt="logo" src={YoutubeLogo} />
            </div>
            <p className={Style.socialLinkTitle}>Youtube</p>
          </div>
        </a>
      )}
      {parsed && parsed.Twitter && (
        <a className={Style.marginLeft} href={parsed.Twitter}>
          <div className={Style.socialLinkContainer}>
            <div className={Style.socialLogo}>
              <img alt="logo" src={TwitterLogo} />
            </div>
            <p className={Style.socialLinkTitle}>Twitter</p>
          </div>
        </a>
      )}
      {parsed && parsed.Facebook && (
        <a className={Style.marginLeft} href={parsed.Facebook}>
          <div className={Style.socialLinkContainer}>
            <div className={Style.socialLogo}>
              <img alt="logo" src={FacebookLogo} />
            </div>
            <p className={Style.socialLinkTitle}>Facebook</p>
          </div>
        </a>
      )}
      {parsed && parsed.MLS && (
        <a className={Style.marginLeft} href={parsed.MLS}>
          <div className={Style.socialLinkContainer}>
            <div className={Style.socialLogo}>
              <img alt="logo" src={MLSLogo} />
            </div>
            <p className={Style.socialLinkTitle}>MLS </p>
          </div>
        </a>
      )}
    </div>
  );
};

export { SocialLinks };
