import Skeleton from "react-loading-skeleton";
import React from "react";
import Style from "./ListingDetailsSkeletonLoading.module.css";
import { useMediaQuery } from 'react-responsive'

const ListingDetailsSkeletonLoading = () => {
    const isDesktopOrLaptop = useMediaQuery({
      query: "(max-width: 1800px)",
    });
  return (
    <div className={Style.container}>
      <div className={Style.mainContainer}>
        <div className={Style.content}>
          <h2 style={{ textAlign: "left" }}>Property Details</h2>
          {/* image section */}
          <div className={Style.Imagecontainer}>
            <div style={{ flex: 1 }}>
              <Skeleton
                className={Style.mainImage}
                width={isDesktopOrLaptop ? "100%" : 900}
              />
              {/* <img className={Style.mainImage} src={images[0]} alt="listing main" /> */}
            </div>
            <div
              style={{
                flex: 1,
                marginLeft: "28px",
                // border: "1px solid black",
                height: "540px",

                // overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                // flexWrap:'nowrap'
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // border: "1px solid black",
                  marginRight: "25px",
                }}
              >
                <Skeleton
                  className={Style.smallImage}
                  width={ 360}
                />
                <Skeleton
                  style={{ marginTop: "25px" }}
                  className={Style.smallImage}
                  width={ 360}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Skeleton
                  className={Style.smallImage}
                  width={ 360}
                />
                <Skeleton
                  style={{ marginTop: "25px" }}
                  className={Style.smallImage}
                  width={  360}
                />
              </div>
            </div>
          </div>
          {/*PropertyType*/}
          <div className={Style.twoCol}>
            <div className={Style.left}>
              <div className={Style.listingInfoContainer}>
                <Skeleton className={Style.listPrice} width={400} />
                <Skeleton className={Style.UnparssedAddress} width={700} />
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: "37px",
                  }}
                  // className={Style.itemContainer}
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Skeleton
                      className={Style.listPrice}
                      width={300}
                      height={40}
                    />
                    <Skeleton
                      className={Style.UnparssedAddress}
                      width={300}
                      height={40}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Skeleton
                      className={Style.listPrice}
                      width={300}
                      height={40}
                    />
                    <Skeleton
                      className={Style.UnparssedAddress}
                      width={300}
                      height={40}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Skeleton
                      className={Style.listPrice}
                      width={300}
                      height={40}
                    />
                    <Skeleton
                      className={Style.UnparssedAddress}
                      width={300}
                      height={40}
                    />
                  </div>
                </div>
              </div>
              <div className={Style.listingInfoContainer}>
                <h2 className={Style.text}>About this home</h2>
                <p className={Style.description}>
                  <Skeleton width={isDesktopOrLaptop ? 900 : 1130} count={2} />
                  <Skeleton width={600} />
                  <Skeleton width={isDesktopOrLaptop ? 900 : 1130} count={1} />
                  <Skeleton width={200} />
                </p>
              </div>
              <div className={Style.listingInfoContainer}>
                <h2 className={Style.text}>Key Details</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      width: "45%",
                      marginRight: "25px",
                    }}
                  >
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      width: "45%",
                    }}
                  >
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 370 : 500}
                      height={40}
                      style={{ marginBottom: "25px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={Style.listingInfoContainer}>
                <h2 className={Style.text}>Facts And Features</h2>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "30px",
                    // border: '1px solid black'
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      width: "30%",
                      marginRight: "25px",
                    }}
                  >
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      width: "30%",
                      marginRight: "25px",
                    }}
                  >
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-between",
                      width: "30%",
                    }}
                  >
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                    <Skeleton
                      width={isDesktopOrLaptop ? 200 : 300}
                      height={35}
                      style={{ marginBottom: "25px" }}
                    />
                  </div>
                </div>
              </div>
              <div className={Style.listingInfoContainer}>
                <h2 className={Style.text}>Listing Location</h2>
                <Skeleton
                  with={"100%"}
                  height={540}
                  style={{ marginTop: "30px" }}
                />
              </div>
            </div>
            <div className={Style.right}>
              <div
                className={Style.agentContainer}
                // style={{ border: "1px solid red" }}
              >
                <Skeleton width={"100%"} height={"100%"} />
                {/* <div className={Style.contentContainer } style={{border:'1px solid red',height:'150px'}}>
                  <Skeleton className={Style.agentImage} circle={true} />
                  <div style={{ paddingLeft: "15px",height:'100%' }}>
                    <Skeleton
                      style={{ marginBottom: "2px" }}
                      width={220}
                      height={32}
                    />
                    <Skeleton width={210}height={25} />
                    <Skeleton width={120}height={25} />
                  </div>
                </div> */}

                {/* <Skeleton
                  className={Style.activeListing}
                  width={"95%"}
                  height={30}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    width: "95%",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "12px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "12px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "25px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                </div> */}
              </div>
              <div className={Style.officeContainer}>
                {/* <h2 className={Style.officeText}>Listing Office</h2> */}
                <Skeleton
                  width={200}
                  height={30}
                  style={{ marginTop: "15px" }}
                />
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    width: "95%",
                    marginTop: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "8px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "8px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "25px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                </div> */}
              </div>
              {/* <div className={Style.officeContainer}>
                <h2 className={Style.officeText}>Listing Agent</h2>
                <Skeleton
                  width={200}
                  height={30}
                  style={{ marginTop: "15px" }}
                />
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "space-between",
                    width: "95%",
                    marginTop: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "8px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "8px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "80%",
                      marginBottom: "25px",
                    }}
                  >
                    <Skeleton width={30} height={30} />
                    <Skeleton width={200} height={30} />
                  </div>
                </div> 
              </div> */}
            </div>
          </div>
          <div
            style={{ width: "100%", marginTop: "30px", marginBottom: "100px" }}
          >
            <h2 className={Style.officeText}>Nearby listings</h2>
            <div
              style={{
                width: "100%",
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "20%",
                  height: "350px",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={"100%"} height={350} />
                {/* <Skeleton width={"100%"} height={20} />
                <Skeleton width={"100%"} height={20} /> */}
              </div>
              <div
                style={{
                  width: "20%",
                  height: "350px",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={"100%"} height={350} />
                {/* <Skeleton width={"100%"} height={20} />
                <Skeleton width={"100%"} height={20} /> */}
              </div>
              <div
                style={{
                  width: "20%",
                  height: "350px",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={"100%"} height={350} />
                {/* <Skeleton width={"100%"} height={20} />
                <Skeleton width={"100%"} height={20} /> */}
              </div>
              <div
                style={{
                  width: "20%",
                  height: "350px",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={"100%"} height={350} />
                {/* <Skeleton width={"100%"} height={20} />
                <Skeleton width={"100%"} height={20} /> */}
              </div>
              <div
                style={{
                  width: "20%",
                  height: "350px",
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={"100%"} height={350} />
                {/* <Skeleton width={"100%"} height={20} />
                <Skeleton width={"100%"} height={20} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ListingDetailsSkeletonLoading };
